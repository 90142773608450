import React, {FC, useEffect, useState} from 'react';
import './WeekView.scss';
import Menu from '../../components/CustomMenu/Menu';
import AppointmentForm from "../../components/AppointmentForm/AppointmentForm";
import moment from "moment";
import Appointment from "../../interfaces/Appointment";
import WeekUtils, {DATE_FORMAT} from "../../utils/WeekUtils";
import store from "../../redux/store";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import {checkAuthErrorByCode} from "../../services/URLUtil";
import {useNavigate} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import {setCopy, setCopyIsCut} from "../../redux/reducers/appSlice";
import {useDispatch} from "react-redux";
import {RequestResponse} from "../../types/RequestResponse";
import AppointmentService from "../../services/AppointmentService";
import {Wording} from "../../enums/Wording";
import {cToast, ToastType} from "../../utils/CustomToaster";
import WeekViewTool, {
    COLUMN_OFFSET_FOR_DAY_INDEX_IN_WEEK,
    ROW_INCLUDE_OFFSET_FOR_WORKING_HOURS_INDEX
} from "./WeekViewTool";
import {CellPosition} from "./interfaces/CellPosition";
import {AppointmentCellPosition} from "./interfaces/AppointmentCellPosition";
import {ContextMenuType} from "../../components/CustomMenu/enums/EContextMenuType";
import {MenuItem} from "../../components/CustomMenu/interfaces/IMenuItem";
import {MenuItemType} from "../../components/CustomMenu/enums/EMenuItemType";
import {ElementPositionOnScreen} from "../../components/CustomMenu/interfaces/IElementPositionOnScreen";
import {WeekViewProps} from "./interfaces/WeekViewProps";
import AppointmentCell from "../../components/AppointmentCell/AppointmentCell";
import useLongPress from "./useLongPress";

const WeekView: FC<WeekViewProps> = ({week, refreshWeek}) => {

    const weekUtils = new WeekUtils();
    const workingHours = store.getState().week.workingHours;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showCreationModal, setShowCreationModal] = useState<boolean>(false);
    const [showCA, setShowCA] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false)

    const [targetCells, setTargetCells] = useState<any>([]);
    const [mousePressed, setMousePressed] = useState(false);
    const [aptForAppointmentForm, setAptForAppointmentForm] = useState<Appointment | null>(null);
    // Utilisé pour la selection de cellules dans le cas d'une création de RDV
    const [targetElements, setTargetElements] = useState<any>([]);

    /////////////// Menu contextuel //////////////////
    // Utilisé un peu partout, mais définie que lors de la gestion du menu contextuel
    const [aptSelectedByContextMenu, setAptSelectedByContextMenu] = useState<Appointment | null>(null);
    const [contextMenuType, setContextMenuType] = useState<ContextMenuType>(ContextMenuType.NONE);
    const [menuPosition, setMenuPosition] = useState<ElementPositionOnScreen>({x: 0, y: 0});
    const [contextMenuCell, setContextMenuCell] = useState<CellPosition>();
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const longPressEvent = useLongPress(() => setShowMenu(true), () => {
    }, {
        shouldPreventDefault: true,
        delay: 500,
    });

    /**
     * Gère la copie de RDV
     */
    const copyAppointment = () => {
        if (aptSelectedByContextMenu) {
            dispatch(setCopyIsCut(false));
            dispatch(setCopy(aptSelectedByContextMenu));
            cToast(Wording.TOAST_APPOINTMENT_COPIED, ToastType.SUCCESS);
        }
    }

    /**
     * Gère la coupe de RDV
     */
    const cutAppointment = () => {
        if (aptSelectedByContextMenu) {
            dispatch(setCopyIsCut(true));
            dispatch(setCopy(aptSelectedByContextMenu));
            cToast(Wording.TOAST_APPOINTMENT_CUT, ToastType.SUCCESS);
        }
    }

    /**
     * Colle un RDV
     */
    const pasteAppointment = async () => {
        const copiedAppointment = store.getState().app.copy;
        let nApt;
        if (copiedAppointment && contextMenuCell) {
            const dayIndex = contextMenuCell.column - COLUMN_OFFSET_FOR_DAY_INDEX_IN_WEEK
            const start = workingHours[contextMenuCell.row - ROW_INCLUDE_OFFSET_FOR_WORKING_HOURS_INDEX]
            const end = start + copiedAppointment.duration
            const dateStr = weekUtils.getDBDate(moment(week.days[dayIndex].timestamp).format(DATE_FORMAT));

            if (!contextMenuCell || !WeekViewTool.isThereEnoughSpaceFor(copiedAppointment, week, contextMenuCell, workingHours)) {
                cToast(Wording.TOAST_NOT_SPACE_AVAILABLE, ToastType.ERROR);
                return false;
            }

            nApt = {
                ...copiedAppointment,
                appointment_id: undefined,
                session_number: undefined,
                tattoo_artist_id: store.getState().app.currentArtist?.id,
                date_start: `${dateStr} ${weekUtils.getStrHourForDB(start)}`,
                date_end: `${dateStr} ${weekUtils.getStrHourForDB(end)}`,
                start,
                end
            }

            const response: RequestResponse = await AppointmentService.postAppointment(store.getState().auth.user.permission, nApt);
            if (response.hasFailed) {
                await checkAuthErrorByCode(response.errorStatus, navigate);
                return false;
            }
            cToast(Wording.TOAST_APPOINTMENT_PASTED, ToastType.SUCCESS);
            setContextMenuCell(undefined)
            refreshWeek();
            if (store.getState().app.copyIsCut) {
                await deleteApt()
                dispatch(setCopyIsCut(false))
                dispatch(setCopy(null));
            }
        }
    }

    const handleEndOfEmptyCellSelection = () => {
        if (!showMenu) {
            if (targetElements.length > 0 && store.getState().app.currentArtist?.id) {
                const firstTargetElementCellPosition = WeekViewTool.getCellPositionFromEvent(targetElements[0]);
                const lastTargetElementCellPosition = WeekViewTool.getCellPositionFromEvent(targetElements[targetElements.length - 1]);
                const nApt = WeekViewTool.getAppointmentByColumnAndRow(week, firstTargetElementCellPosition.column, firstTargetElementCellPosition.row, lastTargetElementCellPosition.row)
                setAptForAppointmentForm(nApt)
                setShowCreationModal(true)
            }
        } else {
            clearBackgroundCellSelection()
        }
    }

    const backgroundCellEventHandler = {
        mouseDown: (e: React.MouseEvent<HTMLDivElement>, row: number, column: number) => {
            longPressEvent.onMouseDown(e)
            setShowMenu(false)
            setMenuPositionFromMouseEvent(e)
            if (e.button !== 0) return;
            setTargetElements([...targetElements, e.target])
            handleContextMenu({
                row,
                column
            }, ContextMenuType.BACKGROUND, aptSelectedByContextMenu)
        },
        mouseUp: (e: React.MouseEvent<HTMLDivElement>) => {
            longPressEvent.onMouseUp(e)
            handleEndOfEmptyCellSelection()
        },
        mouseLeave: (e: React.MouseEvent<HTMLDivElement>) => longPressEvent.onMouseLeave(e),
        mouseOver: (e: React.MouseEvent<HTMLDivElement>) => {
            if (!showMenu) {
                if (e) {
                    const cellPosition = WeekViewTool.getCellPositionFromEvent(e);
                    const lastEltCellPosition = WeekViewTool.getCellPositionFromEvent(targetElements[targetElements.length - 1]);
                    // Gère la sélection de cellules pour la création de RDV
                    if (mousePressed && !targetElements.includes(e.target)) {
                        if (lastEltCellPosition.column === cellPosition.column && lastEltCellPosition.row === cellPosition.row - 1) {
                            setTargetElements([...targetElements, e.target])
                        }
                    }
                }
            } else {
                clearBackgroundCellSelection()
            }
        },
        touchStart: (e: React.TouchEvent<HTMLDivElement>, row: number, column: number) => {
            longPressEvent.onTouchStart(e)
            setShowMenu(false)
            setMenuPositionFromTouchEvent(e)
            setTargetElements([...targetElements, e.target])
            handleContextMenu({
                row,
                column
            }, ContextMenuType.BACKGROUND, aptSelectedByContextMenu)
        },
        touchEnd: (e: React.TouchEvent<HTMLDivElement>) => {
            longPressEvent.onTouchEnd(e)
            handleEndOfEmptyCellSelection()
        },
        contextMenu: (row: number, column: number) => {
            setShowMenu(true)
            handleContextMenu({
                row,
                column
            }, ContextMenuType.BACKGROUND, aptSelectedByContextMenu)
        }
    }

    const appointmentCellEventHandler = {
        contextMenu: (cellPosition: AppointmentCellPosition, apt: Appointment) => {
            onSelectAppointment(cellPosition, apt)
            setShowMenu(true)
        },
        click: (apt: Appointment) => {
            if (!showMenu) {
                setAptForAppointmentForm(apt)
                setShowCreationModal(true)
            }
        },
        mouseDown: (e: React.MouseEvent<HTMLDivElement>, cellPosition: AppointmentCellPosition, apt: Appointment) => {
            onSelectAppointment(cellPosition, apt)
            longPressEvent.onMouseDown(e)
            setShowMenu(false)
            setMenuPositionFromMouseEvent(e)
        },
        mouseUp: (e: React.MouseEvent<HTMLDivElement>) => longPressEvent.onMouseUp(e),
        mouseLeave: (e: React.MouseEvent<HTMLDivElement>) => longPressEvent.onMouseLeave(e),
        touchStart: (e: any, cellPosition: AppointmentCellPosition, apt: Appointment) => {
            onSelectAppointment(cellPosition, apt)
            longPressEvent.onTouchStart(e)
            setShowMenu(false)
            setMenuPositionFromTouchEvent(e)
        },
        touchEnd: (e: any) => longPressEvent.onTouchEnd(e),
    }

    const onSelectAppointment = (cellPosition: AppointmentCellPosition, apt: Appointment) => {
        handleContextMenu({
            column: cellPosition.column,
            row: cellPosition.startRow
        }, ContextMenuType.APPOINTMENT, apt)
    }

    const items: MenuItem[] = [
        {
            displayCondition: contextMenuType === ContextMenuType.APPOINTMENT && !!aptSelectedByContextMenu && !!aptSelectedByContextMenu.phone && aptSelectedByContextMenu.phone.length > 0,
            text: "Copier n° tel",
            action: copyTelNum,
            type: MenuItemType.ITEM,
        },
        {
            displayCondition: contextMenuType === ContextMenuType.APPOINTMENT,
            text: "Couper rdv",
            action: cutAppointment,
            type: MenuItemType.ITEM,
        },
        {
            displayCondition: contextMenuType === ContextMenuType.APPOINTMENT,
            text: "Copier rdv",
            action: copyAppointment,
            type: MenuItemType.ITEM,
        },
        {
            displayCondition: (contextMenuType === ContextMenuType.BACKGROUND) && !!store.getState().app.copy,
            text: "Coller",
            action: pasteAppointment,
            type: MenuItemType.ITEM,
        },
        {
            displayCondition: (contextMenuType === ContextMenuType.BACKGROUND) && !store.getState().app.copy,
            text: "Rien à coller",
            type: MenuItemType.ITEM,
        },
        {
            displayCondition: contextMenuType === ContextMenuType.APPOINTMENT,
            type: MenuItemType.SEPARATOR,
        },
        {
            displayCondition: contextMenuType === ContextMenuType.APPOINTMENT,
            text: "Supprimer",
            action: () => setShowConfirm(true),
            type: MenuItemType.ITEM,
            style: {color: "red"}
        },
        {
            displayCondition: contextMenuType !== ContextMenuType.NONE,
            type: MenuItemType.SEPARATOR,
        },
        {
            displayCondition: contextMenuType !== ContextMenuType.NONE,
            text: "Annuler",
            type: MenuItemType.ITEM,
        },
    ]

    const displayDaysHeader = () => {
        let rows = [];
        const today = moment().format("YYYY-MM-DD");
        for (let i = 0; i < week.days.length; i++) {
            const day = week.days[i];
            rows.push(
                <div key={`day-${i}`} className="dayLabelCell" style={{
                    gridColumn: `${i + 2}`,
                    gridRow: `1`,
                    color: `${day.dateStr === today ? "#1DA1F2" : ""}`
                }}>
                    {day.dateTitle + (day.isHoliday ? " (férié)" : "")}
                </div>
            );
        }
        return rows;
    }

    const displayHours = () => {
        let rows = [];
        rows.push(<div key={"left-corner"} className="leftCorner"/>)
        for (let h = 0; h < workingHours.length; h++) {
            rows.push(
                <div key={`hour-${h}`} className="hourCell" style={{gridColumn: '1', gridRow: `${h + 2}`}}>
                    <p>{weekUtils.getSchedulerFormattedHours(workingHours[h])}</p>
                </div>
            );
        }
        return rows
    }

    const displayBackground = () => {
        let rows: JSX.Element[] = [
            <div
                key={"totalSemaine"}
                className={`backgroundCell priceCell`}
                style={{zIndex: 30, gridColumn: `1/8`, gridRow: `${workingHours.length + 2}`}}
            >{showCA && "Total de la semaine : " + weekUtils.getWeekTotalPrice(week) + "€"}</div>
        ];
        for (let d = 0; d < week.days.length; d++) {
            for (let h = 0; h < (workingHours.length); h++) {
                const key = `bg-${d}-${h}`;
                const row = h + 2;
                const column = d + 2;
                // Si dernière ligne
                if (h + 1 === workingHours.length) {
                    rows.push(
                        <div
                            data-key={key}
                            key={key}
                            className={`backgroundCell priceCell disableTextSelection`}
                            style={{gridColumn: `${column}`, gridRow: `${row}`}}
                        >{showCA && weekUtils.getTotalPrice(week.days[d]) + "€"}</div>
                    )
                } else {
                    rows.push(
                        <div
                            data-key={key}
                            key={key}
                            className={`backgroundCell ${WeekViewTool.isLunchtime(row) ? "lunchTime" : ""}`}
                            style={{gridColumn: `${column}`, gridRow: `${row}`}}
                            onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => backgroundCellEventHandler.mouseDown(e, row, column)}
                            onMouseUp={backgroundCellEventHandler.mouseUp}
                            onMouseLeave={backgroundCellEventHandler.mouseLeave}
                            onMouseOver={backgroundCellEventHandler.mouseOver}
                            onTouchStart={(e: React.TouchEvent<HTMLDivElement>) => backgroundCellEventHandler.touchStart(e, row, column)}
                            onTouchEnd={backgroundCellEventHandler.touchEnd}
                            onContextMenu={() => backgroundCellEventHandler.contextMenu(row, column)}
                        />
                    )
                }
            }
            const l = workingHours[workingHours.length - 1];
            if (l) {
                rows.push(
                    <div
                        data-key={`price-${d}`}
                        key={`price-${d}`}
                        className={`backgroundCell priceCell`}
                        style={{gridColumn: `${d + 2}`, gridRow: `${l + 2}`}}
                    />
                )
            }

        }

        return rows;
    }

    const displayAppointments = () => {
        let rows = [];
        for (const weekDay of week.days) {
            const indexOfWeekDay = week.days.indexOf(weekDay);
            for (const apt of weekDay.appointments) {
                const cellPosition: AppointmentCellPosition = WeekViewTool.getCellPositionFromAppointment(apt);
                const key = `apt-${week.days[indexOfWeekDay].appointments.indexOf(apt)}-${indexOfWeekDay}`;
                rows.push(
                    <AppointmentCell
                        uniqueKey={key}
                        key={key}
                        apt={apt}
                        onAptMouseDown={(e: any) => appointmentCellEventHandler.mouseDown(e, cellPosition, apt)}
                        onAptTouchStart={(e: any) => appointmentCellEventHandler.touchStart(e, cellPosition, apt)}
                        onAptClick={() => appointmentCellEventHandler.click(apt)}
                        onAptContextMenu={() => appointmentCellEventHandler.contextMenu(cellPosition, apt)}
                        onAptMouseUp={appointmentCellEventHandler.mouseUp}
                        onAptMouseLeave={appointmentCellEventHandler.mouseLeave}
                        onAptTouchEnd={appointmentCellEventHandler.touchEnd}
                    />
                )
            }
        }
        return rows;

    }

    const displayAppointmentForm = () => {
        return aptForAppointmentForm &&
            <AppointmentForm
                atClose={closeCreationModal}
                onClose={() => setShowCreationModal(false)}
                apt={aptForAppointmentForm}
                onCreate={refreshWeek}
                show={showCreationModal}
            />
    }

    function displayContextMenu() {
        return showMenu &&
            <Menu
                items={items}
                onClose={() => {
                    setContextMenuType(ContextMenuType.NONE)
                    setShowMenu(false)
                }}
                position={menuPosition}
            />
    }

    function displayDeleteConfirmModal() {
        return showConfirm &&
            <ConfirmModal
                message={
                    `Est-vous sûr de vouloir supprimer l'événement du 
                    ${moment(aptSelectedByContextMenu?.date_start).format("DD/MM/YYYY")} à 
                    ${moment(aptSelectedByContextMenu?.date_start).subtract(1, "hour").format("HH:mm")} ?`
                }
                onClose={() => setShowConfirm(false)}
                onConfirm={async () => {
                    setShowConfirm(false)
                    await deleteApt()
                }}
            />
    }

    /**
     * Lié à l'affichage du menu contextuel
     * @param e
     */
    const setMenuPositionFromMouseEvent = (e: React.MouseEvent<HTMLDivElement>) => {
        const newCellPosition: ElementPositionOnScreen = {x: e.pageX, y: e.pageY};
        if (menuPosition !== newCellPosition) {
            setMenuPosition(newCellPosition)
        }
    }

    /**
     * Lié à l'affichage du menu contextuel
     * @param e
     */
    const setMenuPositionFromTouchEvent = (e: React.TouchEvent<HTMLDivElement>) => {
        const newCellPosition: ElementPositionOnScreen = {x: e.touches[0].pageX, y: e.touches[0].pageY};
        if (menuPosition !== newCellPosition) {
            setMenuPosition(newCellPosition)
        }
    }

    /**
     *  Gère le click droit
     *  Défini la position de la cellule (column/row)
     *  Définie le type de context menu (APT / BACKGROUND)
     *  Définir l'élément cliqué grace au "Event.target"
     *  Définie le rdv sélectionné
     * @param cellPosition
     * @param contextMenuType
     * @param apt
     */
    const handleContextMenu = (cellPosition: CellPosition, contextMenuType: ContextMenuType, apt: Appointment | null) => {
        setContextMenuCell(cellPosition);
        setContextMenuType(contextMenuType);
        setAptSelectedByContextMenu(apt)
    }

    const clearBackgroundCellSelection = () => {
        setTargetElements([]);
        setTargetCells([]);
    }

    const closeCreationModal = () => {
        setShowCreationModal(false)
        setTargetCells([]);
        setTargetElements([]);
        setAptForAppointmentForm(null)
    }

    const formatTargetElementsToCells = () => {
        let cells = [];
        for (const e of targetElements) {
            const cellPosition = WeekViewTool.getCellPositionFromEvent(e);
            const rowEnd = +cellPosition.row + 1;
            const str = targetElements.indexOf(e) === 0 ? "Nouvel évènement" : ""
            cells.push(
                <div
                    key={`newAptCell-${cellPosition.column}-${cellPosition.row}`}
                    className={`aptCell newAptCell`}
                    style={{gridArea: `${cellPosition.row} / ${cellPosition.column} / ${rowEnd}`}}
                    onMouseOver={clearBackgroundCellSelection}
                >
                    {str}
                </div>
            )
        }
        setTargetCells(cells);
    }

    const deleteApt = async () => {
        const aptId = store.getState().app.copyIsCut ? store.getState().app.copy?.appointment_id : aptSelectedByContextMenu?.appointment_id;
        if (aptId) {
            const response: RequestResponse = await AppointmentService.deleteAppointment(store.getState().auth.user.permission, aptId);
            if (response.hasFailed) return await checkAuthErrorByCode(response.errorStatus, navigate);
            cToast(store.getState().app.copyIsCut ?
                Wording.TOAST_OLD_APPOINTMENT_DELETED : Wording.TOAST_APPOINTMENT_DELETED, ToastType.SUCCESS);
            refreshWeek();
        }
    }

    useEffect(() => {
        document.addEventListener("contextmenu", e => e.preventDefault());
        formatTargetElementsToCells();
    }, [targetElements, week])

    function copyTelNum() {
        if (aptSelectedByContextMenu) {
            navigator.clipboard
                .writeText(aptSelectedByContextMenu.phone)
                .then(_ => cToast(Wording.TOAST_TEL_NUMBER_COPIED, ToastType.SUCCESS));
        }
    }


    return (
        <div
            className="week disableTextSelection"
            onMouseDown={() => setMousePressed(true)}
            onMouseUp={() => setMousePressed(false)}

        >
            <div className="weekBox disableTextSelection">
                {displayHours()}
                {displayDaysHeader()}
                {displayBackground()}
                {displayAppointments()}
                {targetCells}
            </div>
            <div className={"toggleCA disableTextSelection"} onClick={() => setShowCA(!showCA)}>
                {!showCA ? "Révéler le chiffre d'affaire" : 'Masquer le chiffre d\'affaire'}
            </div>
            {displayAppointmentForm()}
            {displayContextMenu()}
            {displayDeleteConfirmModal()}
        </div>
    );
}

export default WeekView;
