import {API_URL} from "../settings";
import store from "../redux/store";
import {setAccessToken} from "../redux/reducers/authSlice";

const API_ENDPOINTS = {
    AUTH_LOGIN: `${API_URL}/auth/login`,
    AUTH_REFRESH_TOKEN: `${API_URL}/auth/refreshToken`,
    LOGIN_VERIFY: `${API_URL}/auth/verify`,
    GET_WEEK: `${API_URL}/appointment/week`,
    GET_ARTISTS: `${API_URL}/artist`,
    POST_APT: `${API_URL}/appointment/`,
    DELETE_APT: `${API_URL}/appointment/delete`,
    SYNC: `${API_URL}/appointment/sync`,
    GET_HOURS: `${API_URL}/week/hours`,
    SET_WEEK_INDISPO: `${API_URL}/week/indispo`,
    GET_MESSAGE: (artistId: number, dateStart: string) => `${API_URL}/message/${artistId}/${dateStart}`,
    POST_MESSAGE: `${API_URL}/message/update`,
    DEACTIVATE_ARTIST: (artistId: number) => `${API_URL}/artist/deactivate/${artistId}`,
    ACTIVATE_ARTIST: `${API_URL}/artist/activate`,
    CREATE_ARTIST: `${API_URL}/artist/create`
}

export default API_ENDPOINTS;

export const API_ENDPOINTS_URI = {
    AUTH_LOGIN: `/auth/login`,
    AUTH_REFRESH_TOKEN: `/auth/refreshToken`,
    LOGIN_VERIFY: `/auth/verify`,
    GET_WEEK: `/appointment/week`,
    GET_ARTISTS: `/artist`,
    POST_APT: `/appointment/`,
    DELETE_APT: `/appointment/delete`,
    SYNC: `/appointment/sync`,
    GET_HOURS: `/week/hours`,
    SET_WEEK_INDISPO: `/week/indispo`,
    GET_USERNAMES: "/user/usernames",
    GET_BACKEND_VERSION: "/version",

    GET_MESSAGE: (artistId: number, dateStart: string) => `/message/${artistId}/${dateStart}`,
    POST_MESSAGE: `/message/update`,
    DEACTIVATE_ARTIST: (artistId: number) => `/artist/deactivate/${artistId}`,
    ACTIVATE_ARTIST: `/artist/activate`,
    CREATE_ARTIST: `/artist/create`,
    UPDATE_CREDENTIALS: `/user/credentials`,
}

export const getHeaderWithToken = () => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': store.getState().auth.accessToken
        }
    }
}

export const getHeaderWithRefreshToken = () => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': store.getState().auth.refreshToken
        }
    }
}

export const checkAuthError = async (err: any, navigate: any,) => {
    if (err.response.status === 403 || err.response.status === 401) {
            store.dispatch(setAccessToken(""))
            navigate("/login")
    }
}

export const checkAuthErrorByCode = async (errorCode: number, navigate: any,) => {
    if (errorCode === 403 || errorCode === 401) {
            store.dispatch(setAccessToken(""))
            navigate("/login")
    }
}