import React, {FC, useState} from 'react';
import './AppointmentForm.css';
import Appointment from "../../interfaces/Appointment";
import moment from "moment";
import {AptType} from "../../enums/AptType";
import {PaymentMethod} from "../../enums/PaymentMethod";
import store from "../../redux/store";
import {checkAuthErrorByCode} from "../../services/URLUtil";
import {useNavigate} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import {RequestResponse} from "../../types/RequestResponse";
import AppointmentService from "../../services/AppointmentService";
import {StatusCodes} from "http-status-codes";
import {Wording} from "../../enums/Wording";
import {ESCAPE} from "../../utils/KeyboardHandler";
import {cToast, ToastType} from "../../utils/CustomToaster";
import {
    displayEndHours,
    displayStartHours,
    generateIndispoObject,
    getAppointmentDateTime,
    getDefaultEndingHour,
    getDefaultEndingMinutes,
    getDefaultStartingHour,
    getDefaultStartingMinutes,
    isThereSomethingWrongWithThisAptHours
} from "./AppointmentFormTool";
import DateTool from "../../utils/DateTool";

interface Props {
    apt: Appointment
    atClose: Function,
    onClose: Function,
    onCreate: Function,
    show: boolean
}

const AppointmentForm: FC<Props> = ({apt, atClose, onClose, onCreate, show}) => {
    const workingHours = store.getState().week.workingHours;

    const [formattedDate, setFormattedDate] = useState(moment(apt.date_start).format("YYYY-MM-DD"))

    const [startHour, setStartHour] = useState(getDefaultStartingHour(apt, workingHours));
    const [startMinutes, setStartMinutes] = useState(getDefaultStartingMinutes(apt));
    const [endHour, setEndHour] = useState(getDefaultEndingHour(apt, workingHours));
    const [endMinutes, setEndMinutes] = useState(getDefaultEndingMinutes(apt));

    const [formApt, setFormApt] = useState<Appointment>({
        ...apt,
        firstname: apt.firstname || "",
        lastname: apt.lastname || "",
        is_coloured: !!apt.is_coloured,
        drawing_done: !!apt.drawing_done,
        phone: apt.phone || "",
        session_number: apt.session_number || 0,
        pattern: apt.pattern || "",
        comment: apt.comment || "",
        is_deleted: false, email: "", duration: 0, start: 0, end: 0,
    });

    const [disabled, setDisabled] = useState(apt.appointment_type !== undefined);
    const [isAnUpdate, setIsAnUpdate] = useState(false);

    const navigate = useNavigate();
    const doClose = () => {
        atClose();
        onClose();
    }

    const setIndispo = async () => {
        const id = store.getState().app.currentArtist?.id;
        if (id) {
            const indispo: Appointment = generateIndispoObject(id,
                getAppointmentDateTime(formattedDate, +startHour, +startMinutes),
                getAppointmentDateTime(formattedDate, +endHour, +endMinutes));
            await createEvent(indispo);
        } else {
            cToast(Wording.TOAST_OUPS, ToastType.ERROR);
        }
    }

    const createEvent = async (apt: Appointment) => {
        const response: RequestResponse = await AppointmentService.postAppointment(store.getState().auth.user.permission, apt);
        if (response.hasFailed) {
            await checkAuthErrorByCode(response.errorStatus, navigate)
            if ([StatusCodes.NOT_FOUND, StatusCodes.INTERNAL_SERVER_ERROR].includes(response.errorStatus)) {
                cToast(Wording.TOAST_OUPS, ToastType.ERROR);
            }
        }
        let message = Wording.TOAST_APPOINTMENT_SAVED;
        if (isAnUpdate) message = Wording.TOAST_APPOINTMENT_MODIFIED
        if (apt.appointment_type === AptType.unavailable) message = Wording.TOAST_INDISPO_SET
        cToast(message, ToastType.SUCCESS);
        onCreate();
        doClose();
    }

    document.body.onkeyup = (e: KeyboardEvent) => {
        if (e.code === ESCAPE) doClose()
    }

    const modify = () => {
        setDisabled(false);
        setIsAnUpdate(true);
    }

    const saveApt = async () => {
        await createEvent({
            ...formApt,
            appointment_id: apt.appointment_id,
            tattoo_artist_id: store.getState().app.currentArtist?.id,
            date_start: getAppointmentDateTime(formattedDate, +startHour, +startMinutes),
            date_end: getAppointmentDateTime(formattedDate, +endHour, +endMinutes)
        })
    }

    const isFormNotValid = () => !formApt.appointment_type || !formApt.firstname || !formattedDate;

    return !show ? null : (
        <div className={"creation-modal enableTextSelection"} onClick={doClose}>
            <div onClick={e => e.stopPropagation()} className={"creation-modal-content"}>
                <div className={"creation-modal-header"}>
                    <h4 className={"creation-modal-title"} style={{display: "inline-block"}}>
                        <div style={{marginBottom: 5}}>

                            Création de fiche du &nbsp;
                            <input
                                className={`unstyled modal-title-date-input ${!formattedDate ? "input-error" : ""}`}
                                disabled={disabled}
                                type="date"
                                id="start"
                                required={true}
                                name="trip-start"
                                value={formattedDate}
                                onChange={(e: any) => setFormattedDate(e.target.value)}
                                min={moment().format("YYYY-MM-DD")}
                            />
                        </div>

                        <div>
                            de&nbsp;&nbsp;
                            <select
                                defaultValue={`${DateTool.getHour(apt.date_start)}`}
                                disabled={disabled}
                                className={"smallSelect select  unstyled hour-select "}
                                onChange={(e: any) => setStartHour(e.target.value)}
                            >
                                {displayStartHours(workingHours)}
                            </select>:
                            <select
                                defaultValue={startMinutes === "30" ? "30" : "00"}
                                disabled={disabled}
                                className={"smallSelect select  unstyled hour-select "}
                                onChange={(e: any) => setStartMinutes(e.target.value)}
                            >
                                <option value="30">30</option>
                                <option value="00">00</option>
                            </select>
                            &nbsp;&nbsp;à&nbsp;&nbsp;
                            <select
                                defaultValue={`${DateTool.getHour(apt.date_end)}`}
                                disabled={disabled}
                                className={" smallSelect select unstyled hour-select "}
                                onChange={(e: any) => {
                                    setEndHour(e.target.value);
                                    if(+e.target.value === workingHours[workingHours.length - 1]) {
                                        setEndMinutes("00");
                                    }
                                }}
                            >
                                {displayEndHours(workingHours)}
                            </select>:
                            <select
                                disabled={disabled}
                                className={" smallSelect select  unstyled hour-select "}
                                defaultValue={endMinutes === "30" ? "30" : "00"}
                                onChange={(e: any) => setEndMinutes(e.target.value)}
                            >
                                <option selected={endMinutes==="30"} value="30">30</option>
                                <option selected={endMinutes==="00"} value="00">00</option>
                            </select>
                        </div>

                    </h4>
                </div>
                {isThereSomethingWrongWithThisAptHours(apt, workingHours) &&
                    <div>
                        <p className={"creation-modal-hour-warning"}> Attention ! Ce rendez-vous présente des horaires
                            possiblement erronés, vérifiez-les avec votre client.</p>
                        <p className={"creation-modal-hour-warning"}>Si les horaires ci-dessus sont corrects <button
                            onClick={saveApt}>cliquez ici</button>, sinon modifiez les, puis enregistrez le rendez-vous
                            à nouveau.
                        </p>
                        <p className={"creation-modal-hour-warning"}> Si le problème persiste sur ce rendez-vous malgré
                            avoir cliqué sur le bouton ci-dessus ou avoir modifié les horaires, contactez le
                            support.</p>
                    </div>
                }

                <hr/>

                <div className={"apt-input-group"}>
                    <div className={`ml-10 ${!formApt.appointment_type ? "input-error" : ""}`}>Type</div>
                    <div className={"radio-group"}>
                        <div className={"ta-left"}>
                            <input
                                checked={formApt.appointment_type === AptType.appointment}
                                onChange={() => true}
                                onClick={() => setFormApt({...formApt, appointment_type: AptType.appointment})}
                                disabled={disabled}
                                type="radio"
                                id="appointment"
                                name="aptType"
                                value="appointment"
                            />
                            <label className="radio-label" htmlFor="appointment">
                                <div className="indicator"/>
                                <span className="radio-text">Tatouage</span>
                            </label>
                        </div>
                        <div>
                            <input
                                checked={formApt.appointment_type === AptType.consultation}
                                onChange={() => true}
                                onClick={() => setFormApt({...formApt, appointment_type: AptType.consultation})}
                                disabled={disabled}
                                type="radio"
                                id="consultation"
                                name="aptType"
                                value="consultation"/>
                            <label className="radio-label" htmlFor="consultation">
                                <div className="indicator"/>
                                <span className="radio-text">Consultation</span>
                            </label>
                        </div>
                        <div className={"ta-right"}>
                            <input
                                checked={formApt.appointment_type === AptType.touchUp}
                                onChange={() => true}
                                onClick={() => setFormApt({...formApt, appointment_type: AptType.touchUp})}
                                disabled={disabled}
                                type="radio" id="touchUp"
                                name="aptType"
                                value="touchUp"
                            />
                            <label className="radio-label" htmlFor="touchUp">
                                <div className="indicator"/>
                                <span className="radio-text">Retouche</span>
                            </label>
                        </div>
                    </div>
                </div>

                <div className={"apt-input-group"}>
                    <div className={"ml-10"}>Encre</div>
                    <div className={"radio-group"}>
                        <div className={"ta-left"}>
                            <input
                                checked={!formApt.is_coloured}
                                onChange={() => true}
                                onClick={() => setFormApt({...formApt, is_coloured: false})}
                                disabled={disabled}
                                type="radio"
                                id="notColored"
                                name="isColored"
                            />
                            <label className="radio-label" htmlFor="notColored">
                                <div className="indicator"/>
                                <span className="radio-text">Noir et gris</span>
                            </label>
                        </div>
                        <div className={"ta-right"}>
                            <input
                                checked={formApt.is_coloured}
                                onChange={() => true}
                                onClick={() => setFormApt({...formApt, is_coloured: true})}
                                disabled={disabled}
                                type="radio"
                                id="colored"
                            />
                            <label className="radio-label" htmlFor="colored">
                                <div className="indicator"/>
                                <span className="radio-text">Couleur</span>
                            </label>
                        </div>
                    </div>
                </div>

                <div className={"apt-input-group"}>
                    <div className={"ml-10"}>Dessin terminé</div>
                    <div className={"radio-group"}>
                        <div className={"ta-left"}>
                            <input
                                checked={!formApt.drawing_done}
                                onChange={() => true}
                                onClick={() => setFormApt({...formApt, drawing_done: false})}
                                disabled={disabled}
                                type="radio"
                                id="noDrawing"
                                name="drawingDone"
                            />
                            <label className="radio-label" htmlFor="noDrawing">
                                <div className="indicator"/>
                                <span className="radio-text">Non</span>
                            </label>
                        </div>
                        <div className={"ta-right"}>
                            <input
                                checked={formApt.drawing_done}
                                onChange={() => true}
                                onClick={() => setFormApt({...formApt, drawing_done: true})}
                                disabled={disabled}
                                type="radio"
                                id="drawing"
                                name="drawingDone"
                            />
                            <label className="radio-label" htmlFor="drawing">
                                <div className="indicator"/>
                                <span className="radio-text">Oui</span>
                            </label>
                        </div>
                    </div>
                </div>

                <div className={"creation-modal-body"}>
                    <div className={"col1"}>
                        <div className={"apt-input-group"}>
                            <div className={`${!formApt.firstname ? "input-error" : ""}`}>Prénom</div>
                            <div>
                                <input
                                    className={`${!formApt.firstname ? "input-error" : ""}`}
                                    disabled={disabled}
                                    type={"text"}
                                    value={formApt.firstname}
                                    onChange={(e: any) => setFormApt({...formApt, firstname: e.target.value})}
                                />
                            </div>
                        </div>

                        <div className={"apt-input-group"}>
                            <div>Nom</div>
                            <div>
                                <input
                                    disabled={disabled}
                                    type={"text"}
                                    value={formApt.lastname}
                                    onChange={(e: any) => setFormApt({...formApt, lastname: e.target.value})}
                                />
                            </div>
                        </div>

                        <div className={"apt-input-group"}>
                            <div>Téléphone</div>
                            <div>
                                <input
                                    disabled={disabled}
                                    type={"text"}
                                    maxLength={10}
                                    value={formApt.phone}
                                    onChange={(e: any) => setFormApt({...formApt, phone: e.target.value})}
                                />
                            </div>
                        </div>
                        <div className={"apt-input-group"}>
                            <div>Motif</div>
                            <div>
                                <textarea
                                    disabled={disabled}
                                    rows={1}
                                    value={formApt.pattern}
                                    onChange={(e: any) => setFormApt({...formApt, pattern: e.target.value})}
                                />
                            </div>
                        </div>
                        <div className={"apt-input-group"}>
                            <div>Tarif</div>
                            <div>
                                <input
                                    disabled={disabled}
                                    type={"number"}
                                    min="0"
                                    value={formApt.price}
                                    onChange={(e: any) => setFormApt({...formApt, price: e.target.value})}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={"col2"}>
                        <div className={"apt-input-group"}>
                            <div>Tarif total</div>
                            <div>
                                <input
                                    disabled={disabled}
                                    type={"number"}
                                    min="0"
                                    value={formApt.total_price}
                                    onChange={(e: any) => setFormApt({...formApt, total_price: e.target.value})}
                                />
                            </div>
                        </div>

                        <div className={"apt-input-group"}>
                            <div>Arrhes</div>
                            <div>
                                <input
                                    disabled={disabled}
                                    type={"number"}
                                    value={formApt.arrhes}
                                    min="0"
                                    onChange={(e: any) => setFormApt({...formApt, arrhes: e.target.value})}
                                />
                            </div>
                        </div>

                        <div className={"apt-input-group"} style={{marginTop: 7, marginBottom: 7}}>
                            <div className={"ml-10"}> Moyen de paiement</div>
                            <div className={"radio-group"} style={{justifyContent: "space-around"}}>
                                <div className={"ta-left"}>
                                    <input
                                        checked={formApt.payment_type === PaymentMethod.money}
                                        onClick={() => setFormApt({...formApt, payment_type: PaymentMethod.money})}
                                        onChange={() => true}
                                        disabled={disabled}
                                        type="radio"
                                        id="money"
                                        name="paymentType"
                                    />
                                    <label className="radio-label" htmlFor="money">
                                        <div className="indicator"/>
                                        <span className="radio-text">Espèce</span>
                                    </label>
                                </div>
                                <div className={"ta-right"}>
                                    <input
                                        checked={formApt.payment_type === PaymentMethod.cheque}
                                        onClick={() => setFormApt({...formApt, payment_type: PaymentMethod.cheque})}
                                        onChange={() => true}
                                        disabled={disabled}
                                        type="radio"
                                        id="cheque"
                                        name="paymentType"
                                    />
                                    <label className="radio-label" htmlFor="cheque">
                                        <div className="indicator"/>
                                        <span className="radio-text">Chèque</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className={"apt-input-group"}>
                            <div> # de session</div>
                            <div>
                                <input
                                    disabled={disabled}
                                    type={"number"}
                                    value={formApt.session_number}
                                    onChange={(e: any) => setFormApt({...formApt, session_number: e.target.value})}
                                />
                            </div>
                        </div>

                        <div className={"apt-input-group"}>
                            <div>Commentaire</div>
                            <div>
                                <input
                                    disabled={disabled}
                                    type={"text"}
                                    value={formApt.comment}
                                    onChange={(e: any) => setFormApt({...formApt, comment: e.target.value})}
                                />
                            </div>
                        </div>
                    </div>

                </div>

                {(isFormNotValid()) &&
                    <p className={"modal-error-message"}>Merci de renseigner les champs en rouge.</p>}
                <div className={"creation-modal-footer"}>

                    {disabled ?
                        <button onClick={modify}
                                className={"btn btn-creation no-radius creation-btn"}>Modifier</button> :
                        <button onClick={saveApt} className={"btn btn-creation no-radius creation-btn"}
                                disabled={isFormNotValid()}>Enregistrer</button>}
                    {(apt && !apt.appointment_id) && <button onClick={setIndispo}
                                                             className={"btn btn-creation no-radius unavailable-btn"}>Indispo</button>}
                    <button onClick={doClose} className={"btn close-btn btn-creation no-radius"}>Annuler</button>
                </div>
            </div>
        </div>
    )

}
export default AppointmentForm;