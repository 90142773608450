import store from "../redux/store";
import Artist from "../interfaces/Artist";
import {setArtists} from "../redux/reducers/appSlice";
import {RequestResponse} from "../types/RequestResponse";
import api, {emptyRequestResponse} from "./api";
import API_ENDPOINTS, {API_ENDPOINTS_URI, getHeaderWithToken} from "./URLUtil";
import {CreateArtist} from "../interfaces/request/CreateArtist";


async function activateArtist(artistId: number): Promise<RequestResponse> {
    let response: RequestResponse = emptyRequestResponse;

    return api.post(API_ENDPOINTS_URI.ACTIVATE_ARTIST, {artistId})
        .then((res: any) => {
            response.hasFailed = false;
            response.data = res.data;
            return response;
        })
        .catch((err: any) => {
            response.hasFailed = true;
            response.errorMessage = err.message;
            response.errorStatus = err.response.status;
            return response
        })
}

async function createArtist(firstName: string,
                            lastName: string,
                            gender: boolean): Promise<RequestResponse> {
    let response: RequestResponse = emptyRequestResponse;
    const request: CreateArtist = {
        firstName,
        lastName,
        gender
    }
    return api.post(API_ENDPOINTS.CREATE_ARTIST, request)
        .then((res: any) => {
            response.hasFailed = false;
            response.data = res.data;
            return response;
        })
        .catch((err: any) => {
            response.hasFailed = true;
            response.errorMessage = err.message;
            response.errorStatus = err.response.status;
            return response
        })
}

async function getArtists(): Promise<RequestResponse> {
    let response: RequestResponse = emptyRequestResponse;

    return api.post(API_ENDPOINTS_URI.GET_ARTISTS)
        .then((res: any) => {
            response.hasFailed = false;
            response.data = res.data.result;
            return response;
        })
        .catch((err: any) => {
            response.hasFailed = true;
            response.errorMessage = err.message;
            response.errorStatus = err.response.status;
            return response
        })
}

async function deactivateArtist(artistId: number): Promise<RequestResponse> {
    let response: RequestResponse = emptyRequestResponse;

    return api.delete(API_ENDPOINTS_URI.DEACTIVATE_ARTIST(artistId))
        .then((res: any) => {
            response.hasFailed = false;
            response.data = res.data.result;
            return response;
        })
        .catch((err: any) => {
            response.hasFailed = true;
            response.errorMessage = err.message;
            response.errorStatus = err.response.status;
            return response
        })
}

const ArtistService = {
    getArtists,
    createArtist,
    activateArtist,
    deactivateArtist,
    setArtists : (artists : Artist[]) => store.dispatch(setArtists(artists)),
    getArtistsFromStore : () => store.getState().app.artists,
}

export default ArtistService;