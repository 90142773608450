import React, {useEffect, useState} from 'react';
import "./Sync.scss";
import moment from "moment";
import store from "../../redux/store";
import Artist from "../../interfaces/Artist";
import {checkAuthErrorByCode} from "../../services/URLUtil";
import {useNavigate} from "react-router-dom";
import Loader from "../Loader/Loader";
import {RequestResponse} from "../../types/RequestResponse";
import AppointmentService from "../../services/AppointmentService";
import {Wording} from "../../enums/Wording";
import {cToast, ToastType} from "../../utils/CustomToaster";

const Sync = () => {
    const initDataObject = () => {
        let d: any = {};
        for (let a of store.getState().app.artists) {
            d[`${a.id}`] = {artist: a, wanted: 0, checked: false}
        }
        return d
    }
    const defaultSelectedMonth = moment().subtract(1, 'months').format('MM');
    const defaultSelectedYear = moment().year();
    const [extractFrom, setExtractFrom] = useState(moment().format("YYYY-MM"))
    const [extractTo, setExtractTo] = useState(moment().format("YYYY-MM"))
    const [formData, setFormData] = useState(initDataObject())
    const [refresh, setRefresh] = useState(1)
    const [syncMonth, setSyncMonth] = useState(moment().format("YYYY-MM"))
    const [loading, setLoading] = useState(false)
    const [selectedYear, setSelectedYear] = useState(`${defaultSelectedYear}`)
    const [selectedMonth, setSelectedMonth] = useState(`${defaultSelectedMonth}`)
    const navigate = useNavigate();

    const handleSyncInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newFormData: any = formData;
        // @ts-ignore
        newFormData[e.target.dataset["id"]].wanted = +e.target.value

        setFormData(newFormData)
        setRefresh(refresh + 1)
    }
    const handleSyncCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newFormData: any = formData;
        // @ts-ignore
        newFormData[e.target.dataset["id"]].checked = e.target.checked
        setFormData(newFormData)
        setRefresh(refresh + 1)
    }
    const handleSyncMonth = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSyncMonth(e.target.value)
    }
    const handleFocus = (e: any) => e.target.select();

    const displaySyncInputs = () => {
        let rows = [];
        const artists: Artist[] = store.getState().app.artists.filter(artist => artist.active);
        for (let i in artists) {
            const cArtist = artists[i];
            // @ts-ignore
            rows.push(
                <div key={`input-group-${i}`} className={"sync-input-group"}>
                    <div className={" inputCBDiv"}>
                        <input
                            data-id={cArtist.id}
                            id={`${cArtist.id}`}
                            key={`cb-${i}`}
                            className={"checkbox"}
                            type="checkbox"
                            // @ts-ignore
                            checked={formData[`${cArtist.id}`].checked}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSyncCheckbox(e)}
                        />
                    </div>
                        <label className={formData[`${cArtist.id}`].checked ? "blackLabel" : "greyLabel"}
                               htmlFor={`${cArtist.id}`}>{artists[i].firstname}</label>
                        <input
                            key={`nb-${i}`}
                            data-id={cArtist.id}
                            className={formData[`${cArtist.id}`].checked ? "inputCA blackCA" : "inputCA greyCA"}
                            type="number"
                            onFocus={handleFocus}
                            // @ts-ignore
                            value={+formData[`${cArtist.id}`].wanted}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSyncInput(e)}
                        />
                        <p style={{height: "100%", margin: 0, padding: 10}}>€</p>
                </div>
            )
        }
        return rows;
    }

    const handleSyncClick = async () => {
        // Vérifier si la date est conforme
        const syncDate = `${selectedYear}-${selectedMonth}`;
        if (moment(syncDate, "YYYY-MM", true).isValid()) {
            setLoading(true);
            const response: RequestResponse = await AppointmentService.sync(syncDate, formData);
            if (response.hasFailed) {
                setLoading(false);
                return await checkAuthErrorByCode(response.errorStatus, navigate)
            }
            if (response.data) {
                setLoading(false);
                cToast(Wording.TOAST_SIMULATION_DONE, ToastType.SUCCESS);
            }
        } else {
            cToast(Wording.WRONG_SIMULATION_DATE_FORMAT, ToastType.ERROR);
        }
    }

    useEffect(() => {
        setFormData(formData)
    }, [refresh])

    const months = [
        {label: "Janvier", value: "01"},
        {label: "Février", value: "02"},
        {label: "Mars", value: "03"},
        {label: "Avril", value: "04"},
        {label: "Mai", value: "05"},
        {label: "Juin", value: "06"},
        {label: "Juillet", value: "07"},
        {label: "Aout", value: "08"},
        {label: "Septembre", value: "09"},
        {label: "Octobre", value: "10"},
        {label: "Novembre", value: "11"},
        {label: "Décembre", value: "12"}
    ];
    const years = [
        {label: `${moment().year()}`, value: `${moment().year()}`},
        {label: `${moment().year() - 1}`, value: `${moment().year() - 1}`},
    ]

    function handleSelectedYear(e: any) {
        setSelectedYear(e.target.value);
    }


    function handleSelectedMonth(e: any) {
        setSelectedMonth(e.target.value);
    }

    return (
        <>
            {loading ?
                <Loader message={Wording.SIMULATION_RUNNING}/> :

                (
                    <>
                        <div className={"sync-container"}>
                            <div className={"sync-form"}>
                                <h3>Synchronisation des données</h3>
                                <div className={"sync-disclaimer"}>
                                    <p>

                                        Pour ne pas effectuer d'opération pour un tatoueur, il suffit de laisser
                                        décochée
                                        la case devant celui-ci.
                                        <br/>
                                        <br/>
                                        Pour synchroniser sans réorganiser, il suffit de laisser les champs de C.A à 0€.
                                        <br/>
                                        Le CA calculé sera alors le C.A. réel.
                                        <br/>
                                        <br/>
                                        Il est possible de synchroniser en même temps un artiste avec le C.A réel
                                        et un autre avec un CA spécifique.
                                        <br/>
                                        Il suffit de cocher les cases à cocher en face des deux artistes et de laisser
                                        le champ à 0€
                                        pour le premier et de saisir un CA spécifique pour le second.
                                    </p>
                                </div>
                                <div className={"sync-input-group columnDirectionOnSmartphone"}>
                                    <label htmlFor="syncStartMonth">Synchroniser pour le mois de</label>
                                    <div className={"selectBox"}>
                                        <select className={"select"} style={{width: "60%"}}  value={selectedMonth} onChange={handleSelectedMonth}>
                                            {months.map((month) => (
                                                <option
                                                    selected={month.value === defaultSelectedMonth}
                                                    value={month.value}>
                                                    {month.label}
                                                </option>
                                            ))}
                                        </select>
                                        &nbsp;
                                        <select className={"select"} onChange={handleSelectedYear} value={selectedYear} style={{width: "30%"}} >
                                            {years.map((year) => (
                                                <option selected={year.value === `${defaultSelectedYear}`} value={year.value}>{year.label}</option>
                                            ))}
                                        </select>

                                    </div>
                                </div>
                                {refresh && displaySyncInputs()}
                                <button className={"btn"} onClick={handleSyncClick}>Synchroniser les données</button>
                            </div>
                            <div className={"export-form"}>
                                <h3>Extraction</h3>
                                <div className={"sync-input-group columnDirectionOnSmartphone"}>
                                    <label htmlFor="extract-from">Extraire à partir de</label>
                                    <input type="text" id="extract-from" value={extractFrom}
                                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => setExtractFrom(e.target.value)}/>
                                </div>
                                <div className={"sync-input-group columnDirectionOnSmartphone"}>
                                    <label htmlFor="extract-to">Extraire jusqu'en</label>
                                    <input type="text" id="extract-to" value={extractTo}
                                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => setExtractTo(e.target.value)}/>
                                </div>
                                <div className={"sync-input-group columnDirectionOnSmartphone"}>
                                    <label htmlFor="extractType">Type</label>
                                    <select className={"select"} name="extractType" id="extractType">
                                        <option value="ghost">Rapport fantôme</option>
                                        <option value="real">Rapport réel</option>
                                    </select>
                                </div>
                                <button className={"btn"}
                                        onClick={() => cToast(Wording.TOAST_FEATURE_INCOMING, ToastType.INFO)}>{Wording.BUTTON_GENERATE_REPORT}
                                </button>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    )
}

export default Sync;