import store from "../redux/store";
import {setUser} from "../redux/reducers/authSlice";
import User from "../interfaces/User";
import {RequestResponse} from "../types/RequestResponse";
import api, {emptyRequestResponse} from "./api";
import {API_ENDPOINTS_URI} from "./URLUtil";
import {UpdateCredentialsRequest} from "../interfaces/request/UpdateCredentialsRequest";

const UserService = {
    setUser: (user: User) => store.dispatch(setUser(user)),
    updateCredentials: (request: UpdateCredentialsRequest): Promise<RequestResponse> => {
        let response: RequestResponse = emptyRequestResponse;

        return api.post(API_ENDPOINTS_URI.UPDATE_CREDENTIALS, request)
            .then((res: any) => {
                response.hasFailed = false;
                response.data = res.data;
                return response;
            })
            .catch((err: any) => {
                response.hasFailed = true;
                response.errorMessage = err.message;
                response.errorStatus = err.response.status;
                return response
            })

    },
    getUsernames  : () :  Promise<RequestResponse> => {
        let response: RequestResponse = emptyRequestResponse;

        return api.get(API_ENDPOINTS_URI.GET_USERNAMES)
            .then((res: any) => {
                response.hasFailed = false;
                response.data = res.data;
                return response;
            })
            .catch((err: any) => {
                response.hasFailed = true;
                response.errorMessage = err.message;
                response.errorStatus = err.response.status;
                return response
            })
    }
}

export default UserService;