import WeekView from "../WeekView/WeekView";
import React, {useEffect, useState} from "react";
import store from "../../redux/store";
import WeekUtils from "../../utils/WeekUtils";
import {setWeekStart} from "../../redux/reducers/weekSlice";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight, faCalendarAlt, faDownload, faSync} from "@fortawesome/free-solid-svg-icons";
import './Scheduler.css';
import Artist from "../../interfaces/Artist";
import {GetWeek} from "../../interfaces/request/GetWeek";
import {Week} from "../../types/Week";
import {checkAuthErrorByCode} from "../../services/URLUtil";
import {useNavigate} from "react-router-dom";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {AptType} from "../../enums/AptType";
import {WeekUnavailable} from "../../interfaces/request/WeekUnavailable";
import moment from "moment";
import Message from "../../interfaces/Message";
import {MessageType} from "../../enums/MessageType";
import MessageService from "../../services/MessageService";
import {RequestResponse} from "../../types/RequestResponse";
import WeekService from "../../services/WeekService";
import authService from "../../services/AuthService";
import {Wording} from "../../enums/Wording";
import {RouteList} from "../../enums/RouteList";
import {LEFT, RIGHT} from "../../utils/KeyboardHandler";
import {cToast, ToastType} from "../../utils/CustomToaster";

const getWeekStart = () => store.getState().week.weekStart;

const Scheduler = () => {
    const dispatch = useDispatch();
    const weekUtils = new WeekUtils();

    const [week, setWeek] = useState<Week>()
    const [loading, setLoading] = useState(true)
    const a: Artist = useSelector((state: any) => state.app.currentArtist);
    const navigate = useNavigate()
    const [weekRefresh, fetchWeek] = useState(0);
    const [pickerDate, setPickerDate] = useState(moment().format("YYYY-MM-DD"));
    const refreshWeek = () => fetchWeek(weekRefresh + 1);
    const [message, setMessage] = useState<string>("");
    const [note, setNote] = useState<string>("");
    const [editMode, setEditMode] = useState<boolean>(false);

    const getWeekRequest = async (request: GetWeek): Promise<Week> => {
        const response: RequestResponse = await WeekService.getWeek(request);
        if (response.hasFailed) {
            await checkAuthErrorByCode(response.errorStatus, navigate)
            if (response.errorStatus === 404 || response.errorStatus === 500) {
                return {monday: request.startOfWeek, days: []}
            }
        }
        return response.data;
    }

    const setCurrentWeek = async () => {
        if (a) {
            const res = await getWeekRequest({
                artistId: a.id,
                startOfWeek: getWeekStart(),
                permission: store.getState().auth.user.permission
            })
            setWeek(res)
        }
    }
    const getMessage = async () => {
        setNote("")
        if (a) {
            const response: RequestResponse = await MessageService.getMessage(a.id);
            if (response.hasFailed) await checkAuthErrorByCode(response.errorStatus, navigate)
            if (!response.data || !response.data.result) return setMessage("");
            let result: Message[] = response.data.result;
            for (let data of result) {
                if (data.type === MessageType.message) setMessage(data.message)
                if (data.type === MessageType.note) setNote(data.message)
            }
        }
    }

    const changeWeekByClick = async (weekStart: string) => {
        dispatch(setWeekStart(weekStart));
        await setCurrentWeek()
        await getMessage()
    }

    const onLeftClick = async () => await changeWeekByClick(weekUtils.substractOneWeek(getWeekStart()));
    const onRightClick = async () => await changeWeekByClick(weekUtils.addOneWeek(getWeekStart()));
    const goToday = async () => await changeWeekByClick(weekUtils.getCurrentWeekStart());

    const updateDate = async (e: any) => {
        setPickerDate(e.target.value);
        await changeWeekByClick(moment(e.target.value).startOf("isoWeek").format("YYYY-MM-DD"))
    }

    function handleGesture() {
        if (touchstartX - touchendX > 200) onRightClick()
        if (touchendX - touchstartX > 200) onLeftClick()
    }

    let touchstartX = 0;
    let touchendX = 0;

    const handleTouchStart = (e: any) => touchstartX = e.changedTouches[0].screenX;
    const handleTouchEnd = (e: any) => {
        touchendX = e.changedTouches[0].screenX
        handleGesture()
    }

    document.body.onkeyup = (e: KeyboardEvent) => {
        if (e.code === LEFT) onLeftClick()
        if (e.code === RIGHT) onRightClick()
    }

    const setWeekUnavailable = async () => {
        if (week) {
            for (let d of week.days) {
                for (let a of d.appointments) {
                    if (a.appointment_type !== AptType.unavailable) {
                        cToast(Wording.TOAST_CANNOT_AVAILABLE_WEEK, ToastType.ERROR);
                        return;
                    }
                }
            }
            const request: WeekUnavailable = {
                permission: store.getState().auth.user.permission,
                // @ts-ignore
                artistId: store.getState().app.currentArtist?.id,
                startOfWeek: week.monday
            }

            const response: RequestResponse = await WeekService.setIndispo(request);
            if (response.hasFailed) {
                await checkAuthErrorByCode(response.errorStatus, navigate)
                if (response.errorStatus === 404 || response.errorStatus === 500) return week
            }
            refreshWeek()
            await setCurrentWeek()
            refreshWeek()
            await setCurrentWeek()
        }
    }

    useEffect(() => {
        let isMounted = true;
        setCurrentWeek().then(() => {
            if (isMounted) {
                setLoading(false)
                getMessage().then();
            }
        })

        return () => {
            isMounted = false
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weekRefresh, fetchWeek])

    const exportData = () => toast.info(Wording.TOAST_FEATURE_INCOMING, {toastId: Wording.TOAST_FEATURE_INCOMING});

    const handleMessageClick = (e: any) => {
        if (e.detail === 2) setEditMode(true); // Check double click
    }

    const createNote = async (message: string, type: MessageType) => {
        let request: Message = {
            id: null,
            message,
            type,
            artistId: a.id,
            dateStart: getWeekStart(),
            dateEnd: moment(getWeekStart()).add(5, "days").format("YYYY-MM-DD")
        }

        const response: RequestResponse = await MessageService.postMessage(request);
        if (response.hasFailed) {
            cToast(Wording.TOAST_OUPS, ToastType.ERROR);
            await checkAuthErrorByCode(response.errorStatus, navigate);
            return setEditMode(false);
        }

        cToast(Wording.TOAST_MESSAGE_SENT, ToastType.SUCCESS);
        setEditMode(false);
        await getMessage();
    }

    const sendMessage = async () => await createNote(message, MessageType.message);
    const handleSendNote = async () => await createNote(note, MessageType.note);

    return (
        loading ? <div>Loading</div> : (
            <div className={"scheduler disableTextSelection"} onTouchStart={(e: any) => handleTouchStart(e)}
                 onTouchEnd={(e: any) => handleTouchEnd(e)}>
                <div onClick={handleMessageClick} id="scroll-container">
                    {editMode ? (
                            <div className={"newMessage"}>
                                <input type={"text"} value={message} onChange={(e: any) => setMessage(e.target.value)}/>
                                <button className={"btn"} onClick={sendMessage}>Envoyer</button>
                            </div>
                        )
                        : (message ? <div id="scroll-text">
                            {message}
                        </div> : Wording.ENTER_NEW_MESSAGE)
                    }
                </div>
                <div className={"scheduler-header"}>
                    <div className={"scheduler-buttons"}>
                        <div className={"fakeBtn"}>

                            <button onClick={onLeftClick} className={""}>
                                <FontAwesomeIcon className={"schedulerLeftArrow"} icon={faArrowLeft}/>
                            </button>
                            <span className={"separator"}/>
                            <button onClick={onRightClick} className={""}>
                                <FontAwesomeIcon className={"schedulerRightArrow"} icon={faArrowRight}/>
                            </button>
                            <span className={"separator"}/>
                            <button onClick={goToday} className={"today"}> &#8594; Aujourd'hui</button>
                            <span className={"separator"}/>
                            <button className={" datepicker-toggle"}>
                                <span className={"datepicker-toggle-button"}>
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </span>
                                <input
                                    className={"datepicker-input"}
                                    type="date"
                                    id="week-start-selector"
                                    name="trip-start"
                                    value={pickerDate}
                                    onChange={updateDate}
                                />
                            </button>
                        </div>

                        {authService.isNotSimulationSession() && authService.isNotAdminSession() ?
                            <button className={"btn"} onClick={() => navigate(RouteList.SIMULATION)}>
                                <FontAwesomeIcon icon={faSync}/>
                            </button> : null
                        }
                        <button className={"btn"} onClick={exportData}>
                            <FontAwesomeIcon icon={faDownload}/>
                        </button>
                        <button onClick={setWeekUnavailable} className={"btn unavailable-btn"}>
                            {Wording.WEEK_UNAVAILABLE}
                        </button>
                    </div>
                    <div className={"legend"}>
                        <div>
                            <div className={"appointment"}/>
                            <small>Tatouage</small>
                        </div>
                        <div>
                            <div className={"touchUp"}/>
                            <small>Retouche</small>
                        </div>
                        <div>
                            <div className={"consultation"}/>
                            <small>Consultation</small>
                        </div>
                        <div>
                            <div className={"unavailable"}/>
                            <small>Indisponible</small>
                        </div>
                    </div>
                </div>
                {week && <WeekView
                    refreshWeek={refreshWeek}
                    week={week}
                />}
                <div className={"noteBox"}>
                    <h3>Notes</h3>
                    <textarea value={note} onChange={(e: any) => setNote(e.target.value)}/>
                    <button className={"btn"} style={{width: "fit-content", margin: "auto"}}
                            onClick={handleSendNote}>{Wording.SEND_BUTTON}
                    </button>
                </div>
            </div>
        )
    )
}

export default Scheduler